import React, { useState, useEffect, useRef } from 'react'
import type { PropsWithChildren } from 'react'
import { Divider, Box, styledComponent, SxProps } from '@hermes/web-components'
import ContentTopContainer from './ContentTopContainer'

type FullWidthElementProps = {
  divider?: boolean
  ignorePadding?: boolean
  sx?: SxProps
  containerSx?: SxProps
  id?: string
  mobileOnly?: boolean
}

const borderBox = 'border-box'
const zeroPadding = '0 !important'

const FullWidthElement = styledComponent(Box, {
  shouldForwardProp: (prop: string) => !['screenWidth', 'ignorePadding', 'mobileOnly'].includes(prop)
  // todo: fix complexity
  // eslint-disable-next-line complexity
})<FullWidthElementProps & { screenWidth: string }>(({ theme, screenWidth, ignorePadding, mobileOnly }) => {
  let styles: Record<string, any> = mobileOnly
    ? {}
    : {
        // todo: fix lint
        // eslint-disable-next-line sonarjs/no-nested-template-literals
        marginLeft: `calc(-100vw / 2 + (${screenWidth || `${theme.breakpoints.values.xl}px`} - ${theme.spacing(
          10
        )}) / 2)`,
        width: '100vw',
        boxSizing: borderBox,
        paddingLeft: zeroPadding,
        paddingRight: zeroPadding
      }

  if (!screenWidth) {
    if (!mobileOnly) {
      styles[theme.breakpoints.down('xl')] = {
        marginLeft: `calc(-100vw / 2 + (${theme.breakpoints.values.lg}px - ${theme.spacing(10)}) / 2)`
      }
      styles[theme.breakpoints.down('lg')] = {
        marginLeft: `calc(-100vw / 2 + (${
          ignorePadding ? '100vw' : `${theme.breakpoints.values.sm}px`
        } - ${theme.spacing(ignorePadding ? 0 : 8)}) / 2)`
      }
      styles[theme.breakpoints.down('md')] = {
        marginLeft: `calc(-100vw / 2 + (${
          ignorePadding ? '100vw' : `${theme.breakpoints.values.sm}px`
        } - ${theme.spacing(ignorePadding ? 0 : 6)}) / 2)`
      }
      styles[theme.breakpoints.down('sm')] = {
        marginLeft: `calc(-100vw / 2 + (100vw - ${ignorePadding ? '0px' : theme.spacing(4)}) / 2)`
      }
    } else {
      styles[theme.breakpoints.down('lg')] = {
        marginLeft: `calc(-100vw / 2 + (${
          ignorePadding ? '100vw' : `${theme.breakpoints.values.sm}px`
        } - ${theme.spacing(ignorePadding ? 0 : 8)}) / 2)`,
        width: '100vw',
        boxSizing: borderBox,
        paddingLeft: zeroPadding,
        paddingRight: zeroPadding
      }
      styles[theme.breakpoints.down('md')] = {
        marginLeft: `calc(-100vw / 2 + (${
          ignorePadding ? '100vw' : `${theme.breakpoints.values.sm}px`
        } - ${theme.spacing(ignorePadding ? 0 : 6)}) / 2)`
      }
      styles[theme.breakpoints.down('sm')] = {
        marginLeft: `calc(-100vw / 2 + (100vw - ${ignorePadding ? '0px' : theme.spacing(4)}) / 2)`
      }
    }
  } else if (screenWidth) {
    if (!mobileOnly) {
      styles = {
        ...styles,
        [theme.breakpoints.up('sm')]: {
          marginLeft: `calc(-100vw / 2 + (${screenWidth} - ${theme.spacing(6)}) / 2)`
        },
        [theme.breakpoints.up('md')]: {
          marginLeft: `calc(-100vw / 2 + (${screenWidth} - ${theme.spacing(8)}) / 2)`
        },
        [theme.breakpoints.up('xl')]: {
          marginLeft: `calc(-100vw / 2 + (${screenWidth} - ${theme.spacing(10)}) / 2)`
        },
        [theme.breakpoints.down('lg')]: {
          marginLeft: `calc(-100vw / 2 + ${
            ignorePadding ? `(100vw - ${theme.spacing(0)}) / 2` : `(${screenWidth} - ${theme.spacing(0)}) / 2`
          })`
        },
        [theme.breakpoints.down('md')]: {
          marginLeft: `calc(-100vw / 2 + ${
            ignorePadding ? `(100vw - ${theme.spacing(0)}) / 2` : `(${screenWidth} - ${theme.spacing(4)}) / 2`
          })`
        },
        [theme.breakpoints.down('sm')]: {
          marginLeft: `calc(-100vw / 2 + (${screenWidth} - ${ignorePadding ? '0px' : theme.spacing(4)}) / 2)`
        }
      }
    } else {
      styles = {
        ...styles,
        [theme.breakpoints.down('lg')]: {
          width: '100vw',
          boxSizing: borderBox,
          paddingLeft: zeroPadding,
          paddingRight: zeroPadding,
          marginLeft: `calc(-100vw / 2 + (${screenWidth} - ${theme.spacing(0)}) / 2)`
        },
        [theme.breakpoints.down('md')]: {
          width: '100vw',
          boxSizing: borderBox,
          paddingLeft: zeroPadding,
          paddingRight: zeroPadding,
          marginLeft: `calc(-100vw / 2 + (${screenWidth} - ${ignorePadding ? '0px' : theme.spacing(4)}) / 2)`
        },
        [theme.breakpoints.down('sm')]: {
          marginLeft: `calc(-100vw / 2 + (${screenWidth} - ${ignorePadding ? '0px' : theme.spacing(4)}) / 2)`
        }
      }
    }
  }

  return styles
})

type FullWidthContainerProps = PropsWithChildren<FullWidthElementProps>

function FullWidthContainer({
  children,
  divider,
  ignorePadding = false,
  sx = {},
  id,
  mobileOnly,
  containerSx
}: FullWidthContainerProps) {
  const [screenWidth, setScreenWidth] = useState('')
  const container = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (container.current) {
      setScreenWidth(`${container?.current?.clientWidth}px`)
    }

    const resizeListener = () => {
      setScreenWidth(`${container?.current?.clientWidth}px`)
    }

    window.addEventListener('resize', resizeListener)

    return () => {
      window.removeEventListener('resize', resizeListener)
    }
  }, [container])

  return (
    <FullWidthElement screenWidth={screenWidth} ignorePadding={ignorePadding} sx={sx} id={id} mobileOnly={mobileOnly}>
      <ContentTopContainer sx={containerSx} maxWidth="xl" ref={container}>
        {children}
      </ContentTopContainer>
      {divider && <Divider />}
    </FullWidthElement>
  )
}

FullWidthContainer.defaultProps = {
  divider: false
}

export default FullWidthContainer
