import { ProfileKeyword, ProfileKeywordRelated } from '../../types/keywords'
import { PracticeProfile } from '../../types/practice'
import { Location } from '../../types/index'
import { SpecialistProfile } from '../../types/specialist'

export enum SearchTypes {
  practices = 'practices',
  specialists = 'specialists',
  dentist = 'dentistry',
  careHome = 'care-home'
}

export enum ProfileQueryTypes {
  dentistry = 'dentistry',
  careHome = 'care-home',
  healthAndSocialCare = 'health-and-social-care'
}

export enum ProfileTypes {
  practices = 'practices',
  specialists = 'specialists'
}

export enum SortType {
  relevance = '',
  nearest = 'nearest',
  rating = 'rating',
  recommended = 'recommended'
}

export enum SearchQueries {
  searchString = 'searchString',
  insurer = 'insurer',
  distance = 'distance',
  specialistType = 'specialistType',
  appointmentType = 'appointmentType',
  gender = 'gender',
  language = 'language',
  locationId = 'locationId',
  typeOfSpecialist = 'typeOfSpecialist',
  profileType = 'profileType',
  specialties = 'specialties',
  sortType = 'sortType',
  location = 'location',
  treatment = 'treatment'
}

export type SearchQueriesKeys = (keyof typeof SearchQueries)[]

export interface SearchProfilesPageProviderFilters {
  searchType?: SearchTypes
  profileType: ProfileTypes
  searchString: string
  specialties: string[]
  selectedKeyword: string
  specialistType: string
  distance: string | number
  appointmentType: string
  sortType: SortType
  gender: string
  language: string
  insurer: string
  page: number
  location: string
  locationId: string
  childLocations?: Location[]
  breadcrumb?: { label: string; url: string }[]
  lat: string
  lon: string
  typeOfSpecialist: string
  treatment: string
  parentLocation?: string
  extraSpecialties?: string[]
  type?: string
  urlKeyword: string
}

export interface BuildUrlParams {
  keywordSlug?: string
  procedureConditionSlug?: string | null
  lat?: number
  lon?: number
  slug?: string
  location?: string
  distance?: string | number
  profileType?: ProfileTypes
  appointmentType?: string
  typeOfSpecialist?: string
  gender?: string
  language?: string
  insurer?: string
  page?: number
  sortType?: string
}

export interface SearchProfilesPageProviderState {
  state: {
    currentLocation: Location
    practices: PracticeProfile[]
    specialists: SpecialistProfile[]
    practicesAutocomplete: Pick<PracticeProfile, 'id' | 'images' | 'name' | 'slug'>[]
    specialistsAutocomplete: Pick<SpecialistProfile, 'id' | 'images' | 'firstName' | 'lastName' | 'slug' | 'keywords'>[]
    specialties: Pick<ProfileKeyword, 'id' | 'iconUri' | 'name' | 'slug' | 'type'>[]
    conditionsProcedures: Pick<ProfileKeyword, 'id' | 'iconUri' | 'name' | 'slug' | 'type'>[]
    locations: Location[]
    typeOfSpecialist: ProfileKeywordRelated[]
    relatedKeywords: ProfileKeywordRelated[]
    filters: SearchProfilesPageProviderFilters
    specialty: ProfileKeyword
    isLoading: boolean
    isMainKeywordConditionOrProcedure: boolean
    totalProfiles: number
  }
  resetLocationsAutocomplete: () => void
  buildUrl: (props: BuildUrlParams) => string
  changeFilter: (key: string, value: string | number) => void
  changeSearch: (search: string) => void
  changeLocationCoordinates: (lat: string, lon: string, location?: string) => void
  changeLocationSearch: (search: string, language: string) => void
  changeSpecialty: (id: string, slug: string, procedureOrCondition?: boolean, selectedKeyword?: string) => void
  changeTypeOfSpecialist: (id: string) => void
  changePage: (page: number) => void
  changeSearchType: (searchType: SearchTypes) => void
  changeCurrentLocation: (locationId: string, locationName?: string, slug?: string) => void
  openProfilePage: (slug: string) => void
  reloadData: () => void
}

export interface SearchProfilesPageProviderProps {
  specialists?: SpecialistProfile[]
  practices?: PracticeProfile[]
  typeOfSpecialist: ProfileKeywordRelated[]
  relatedKeywords: ProfileKeywordRelated[]
  defaultFilters: SearchProfilesPageProviderFilters
  totalProfiles: number
  isMainKeywordConditionOrProcedure: boolean
  specialty: ProfileKeyword
  breadcrumb: { label: string; url: string }[]
  conditionsAndProcedures: ProfileKeyword[]
  specialties: ProfileKeyword[]
  locationsAutocomplete: Location[]
  currentLocation: Location
  distance: string
}
