import { PracticeContact } from '../types/practice'

export const NUMBER_PER_PROFILE_REQUEST = 10

export const YOUR_LOCATION_ALIAS = 'Your location'

export const KeyTypes = {
  email: 'mailto:',
  phone: 'tel:',
  externalBookingLink: ''
} as PracticeContact

export enum AppointmentType {
  VIDEO_CONSULTATION = 'videoConsultation'
}
export enum FilterType {
  VIDEO_CONSULTATION = 'video-consultation'
}
export const DEFAULT_SEARCH_DISTANCE = '25'

export const AMOUNT_KEYWORDS = 3

export const SEARCH_FILTERS = {
  DISTANCE: 'distance'
}

export const FILTERS_TO_EXCLUDE_FROM_BREADCRUMBS = [SEARCH_FILTERS.DISTANCE]
