import { useCallback, useContext } from 'react'
import useTranslateMessage from './useTranslateMessage'
import { AppDataContext } from '../providers/AppData'

type Units = { units: 'miles'; queryUnits: 'mi' } | { units: 'kilometres'; queryUnits: 'km' }

const DEFAULT_SEARCH_DISTANCE_UNITS = 'mi'

const unitsMap = {
  mi: 'miles',
  km: 'kilometres'
}

const getUnits = (searchDistanceUnits?: 'mi' | 'km') => {
  const queryUnits =
    searchDistanceUnits && Object.keys(unitsMap).includes(searchDistanceUnits)
      ? searchDistanceUnits
      : DEFAULT_SEARCH_DISTANCE_UNITS
  return { units: unitsMap[searchDistanceUnits || DEFAULT_SEARCH_DISTANCE_UNITS], queryUnits }
}

const parseDistance = (distance: string | number, translatedUnits: string, units: Units, toFixed?: number) => {
  const stringDistance = distance.toString()
  const float = Number.parseFloat(stringDistance).toFixed(toFixed)
  if (Number.isNaN(+float)) {
    return { distanceWithUnits: stringDistance, distance: stringDistance, queryDistance: stringDistance }
  }
  return {
    distanceWithUnits: `${float} ${translatedUnits}`,
    distance: float,
    queryDistance: `${distance}${units.queryUnits}`
  }
}

export const useGetUnitsByLocale = () => {
  const { tenantSettings } = useContext(AppDataContext)
  const translate = useTranslateMessage()
  return useCallback(
    (distance?: string | number, toFixed?: number) => {
      const parsedDistance = distance || ''
      const units = getUnits(tenantSettings?.searchDistanceUnits)
      const translatedUnits = translate(units.units)
      return {
        ...parseDistance(parsedDistance, translatedUnits, units as Units, toFixed),
        units: translatedUnits
      }
    },
    // todo: clarify deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tenantSettings]
  )
}
