import { useRouter } from 'next/router'
import { useCallback, useContext, useMemo } from 'react'
import { sendContactRequest } from '../api/profile'
import { RequestSources, RequestTypes } from '../constants/profile'
import { AppDataContext } from '../providers/AppData'
import { useTrackWindowWidth } from './useTrackWindowWidth'
import { SearchProfilesPageContext } from '../providers/SearchProfilesPage'
import { SearchTypes } from '../providers/SearchProfilesPage/types'

export const useSendAnalytic = (specialistSlug?: string, specialistId?: number, practiceId?: number) => {
  const router = useRouter()
  const { isMobile } = useTrackWindowWidth()
  const { locale: lang } = useContext(AppDataContext)
  const source = isMobile ? RequestSources.MOBILE : RequestSources.DESKTOP
  const isPractice = router.asPath.includes('practice')
  const slug = specialistSlug || (router.query.slug as string)
  return useCallback(
    (requestType: RequestTypes) =>
      sendContactRequest({ requestType, lang, source, isPractice, slug, specialistId, practiceId }),
    // todo: clarify deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isPractice, lang, slug, source]
  )
}

export const useTrackFiltersValues = () => {
  const {
    state: { filters, typeOfSpecialist }
  } = useContext(SearchProfilesPageContext)
  const { language } = useContext(AppDataContext)
  return useMemo(() => {
    const isSpecialistSearch = filters.searchType === SearchTypes.specialists
    const types = isSpecialistSearch ? typeOfSpecialist : []
    return {
      fltr_sp_type: types.find((ts) => ts.id.toString() === filters.specialties[1] || '')?.name?.[language] || 'All',
      fltr_src_distance: String(filters.distance),
      fltr_appointment_type: filters.appointmentType || 'All',
      fltr_gender: filters.gender || 'All',
      fltr_language: filters.language || 'All',
      fltr_insurance: filters.insurer || 'All'
    }
    // todo: clarify deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filters.appointmentType,
    filters.distance,
    filters.gender,
    filters.insurer,
    filters.language,
    filters.searchType,
    filters.specialties,
    typeOfSpecialist
  ])
}
