import { useMediaQuery, useTheme } from '@hermes/web-components'
import { useEffect, useState } from 'react'

export const useTrackWindowWidth = () => {
  const theme = useTheme()
  const [windowWidth, setWindowWidth] = useState(0)

  useEffect(() => setWindowWidth(window.innerWidth), [])

  const xs = useMediaQuery(theme.breakpoints.down('sm'))
  const sm = useMediaQuery(theme.breakpoints.between('sm', 'md'))
  const md = useMediaQuery(theme.breakpoints.between('md', 'lg'))
  const lg = useMediaQuery(theme.breakpoints.between('lg', 'xl'))
  const xl = useMediaQuery(theme.breakpoints.up('xl'))
  return { xs, sm, md, lg, xl, isMobile: xs, isTablet: md || sm, isDesktop: lg || xl, width: windowWidth }
}
