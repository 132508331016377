import getConfigVariable from '../utils/getConfigVariable'
import { ContactRequest } from '../types/index'
import apiRequest from './request'

const basePath = getConfigVariable('BASIC_API_URL')
const internalApi = getConfigVariable('INTERNAL_API_URL')

export const sendContactRequest = async ({
  lang,
  slug,
  requestType,
  source,
  isPractice,
  practiceId,
  specialistId
}: ContactRequest): Promise<boolean> =>
  apiRequest(`${basePath}/${lang}/${isPractice ? 'practices' : 'specialists'}/${slug}/contact-request`, {
    method: 'post',
    body: JSON.stringify({ requestType, source, specialistId, practiceId }),
    headers: { 'Content-Type': 'application/json' }
  })
    .then((r) => r.status === 201)
    .catch(() => false)

export const sendMediaStats = async ({
  locale,
  mediaId,
  specialistId,
  practiceId,
  type,
  duration
}: {
  locale: string
  mediaId: number
  specialistId?: number
  practiceId?: number
  type?: string
  duration?: number
}): Promise<boolean> =>
  apiRequest(`${internalApi}/${locale}/api/media/stats`, {
    method: 'post',
    body: JSON.stringify({ mediaId, specialistId, practiceId, type, duration }),
    headers: { 'Content-Type': 'application/json' }
  })
    .then((r) => r.status === 201)
    .catch(() => false)
