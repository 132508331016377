import { ProfileKeyword, ProfileKeywordRelated } from '../types'

const correctorRelatedKeywordName =
  (language: string) =>
  (item: ProfileKeywordRelated): ProfileKeywordRelated => ({
    ...item,
    name: { [language]: item?.name?.[language]?.trim() }
  })

export const fixProfileKeyword = (item: ProfileKeyword, language: string): ProfileKeyword => ({
  ...item,
  relatedKeywords: item.relatedKeywords.map(correctorRelatedKeywordName(language))
})
