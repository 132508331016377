import { Location } from '../types'

export const extractLocationProps = (str: string) => {
  if (str.startsWith('ext-')) {
    const [, latLonLocation] = str.split('ext-')
    const params = []
    let value = ''
    for (let i = 0; i < latLonLocation.length; i += 1) {
      if (latLonLocation[i] !== '-' || value.length === 0) {
        value += latLonLocation[i]
      } else {
        params.push(value)
        value = ''
      }
      if (i === latLonLocation.length - 1) {
        params.push(value)
      }
    }
    return {
      lat: params?.[0] || '',
      lon: params?.[1] || ''
    }
  }
  return {
    lat: '51.4776721',
    lon: '0.1648559'
  }
}

export const compareLocationSlugs = (a: string, b: string) => a === b
const findLocation = (defaultLocations: Location[], urlLocation: string) =>
  defaultLocations.find((f: Location) => compareLocationSlugs(f.slug, urlLocation))

export default findLocation
